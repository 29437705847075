.timeline {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.timeline-dot {
  background-color: #cccccc;
  border-radius: 50%;
  cursor: not-allowed;
  display: inline-block;
  width: 12px;
  height: 12px;
  position: relative;
}

.timeline-dot .timeline-label {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  letter-spacing: normal;
  position: absolute;
  bottom: -20px;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
  width: 3rem;
}

.timeline-dot.active:hover .timeline-label {
  opacity: 1;
  visibility: visible;
  transition: 0.5s opacity;
}

.timeline-dot.active {
  background-color: #009cde;
  cursor: pointer;
}

.timeline-connector {
  background-color: #cccccc;
  flex: 1;
  height: 2px;
}

.run-tab-group {
  display: flex;
}

.run-tab-button {
  /* background-color: #fff; */
  border: none;
  border-radius: 0;
  color: inherit;
  font-style: italic;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  position: relative;
  margin-bottom: 3rem;
}
.run-tab-button > img {
  position: absolute;
  top: -12px;
  right: -25px;
  /* width: 170px; */
  height: 70px;
}
/* :root {
  --chevron-width: 20px;
} */
/* .run-tab-button::after {
  content: '';
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  width: 0;
  height: 0;
  border-top: var(--chevron-width) solid transparent;
  border-left: calc(var(--chevron-width) / 2) solid #fff;
  border-bottom: var(--chevron-width) solid transparent;
  position: absolute;
  right: calc(var(--chevron-width) * -0.5);
  top: 0;
  z-index: 1;
} */
/* .run-tab-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 10px;
  background: #fff;
  transform: skew(0deg, 50deg);
} */

.run-tab-button.active {
  /* background-color: #009cde;
  color: #fff; */
}
