.h-road {
  width: 100%;
  border-top: 3px solid #63666a;
  border-bottom: 3px solid #63666a;
  height: 51px;
  background: #999999 url('../../images/city-assets/road-bg.svg') repeat-x;
}

.grass {
  background: #3f9c35;
  padding: 0 2rem;
  position: relative;
}

.leaderboard-billboard {
  position: relative;
  z-index: 2;
  min-height: 450px;
  top: -410px;
  background: #fff;
  border: 4px solid #707070;
  padding: 15px;
}

.leaderboard-billboard:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -32px;
  left: 10%;
  width: 80%;
  height: 32px;
  border-left: 15px solid #707070;
  border-right: 15px solid #707070;
}
.leaderboard-trophy-icon {
  position: absolute;
  left: 0;
  top: 5px;
}
hr.leaderboard-header,
hr.leaderboard-footer1,
hr.leaderboard-footer2 {
  border: none;
  width: 55.85%;
  margin: 1.25rem auto;
  opacity: 1;
}

hr.leaderboard-header {
  border-top: 18px #e6f7ff solid;
}
hr.leaderboard-footer1 {
  background: #aee5ae;
  border: 2px #707070 solid;
  height: 9px;
  margin-bottom: 0;
}
hr.leaderboard-footer2 {
  background: #8cc486;
  border: 2px #707070 solid;
  height: 18px;
  margin-top: 0;
  border-top: transparent;
}

table.leaderboard-bb-table thead th {
  background: #eceeef;
  padding: 10px 3px;
}

table.leaderboard-bb-table td {
  padding: 10px 0;
}

table.leaderboard-bb-table tr:first-child td {
  padding: 20px 0 10px 0;
}
