.input-triangle-wrapper {
  background: url(../../images/lob-triangle.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 282px;
  height: 245px;
  margin: calc(40px + 30px) auto 0 auto;
}

.input-triangle-label {
  position: absolute;
}

.input-triangle-label.as {
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.input-triangle-label.ta {
  bottom: -40px;
  left: -40px;
}
.input-triangle-label.cs {
  bottom: -40px;
  right: -40px;
}

.input-triangle-knob {
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
}
