.building {
  position: relative;
  width: 100%;
  padding-bottom: 115%;
  height: auto;
}

.building .building-icon {
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 64px;
  padding-bottom: 100%;
  background-size: 300%;
  background-repeat: no-repeat;
}

.building-underconstruction .building-icon {
  background-position: left 0;
}

.building-working .building-icon {
  background-position: center 0;
}

.building-outofbusiness .building-icon {
  background-position: right 0;
}

.building-type-0 .building-icon {
  background-image: url('../../images/city-assets/building-0.svg');
}
.building-type-1 .building-icon {
  background-image: url('../../images/city-assets/building-1.svg');
}
.building-type-2 .building-icon {
  background-image: url('../../images/city-assets/building-2.svg');
}
.building h5 {
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.loc-point {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-0.7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
