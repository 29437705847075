.explore-welcome-row {
  position: relative;
}
.explore-welcome-row div {
  position: relative;
  height: 150px;
}
.explore-billboard {
  position: relative;
  z-index: 2;
  min-height: 420px;
  top: -400px;
  max-width: 500px;
  padding: 15px;
}
#bbv-canvas-hover {
  cursor: pointer;
}
#bbv-canvas-hover {
  opacity: 0;
}

#bbv-canvas-hover:hover {
  opacity: 0.25;
}

.v-road {
  position: relative;
  top: -3px;
  height: calc(100% + 6px);
  border-left: 3px solid #63666a;
  border-right: 3px solid #63666a;
  width: 51px;
  background: #999999 url('../../images/city-assets/road-v-bg.svg') repeat-y;
}
